


































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import moment from "moment-timezone";

import appointmentStoreModule from "@/store/modules/appointment";
import revenueStoreModule from "@/store/modules/revenue";
import clientStoreModule from "@/store/modules/client";
import { Business, Role } from "@/types";

const { mapActions: appointmentActions, mapGetters: appointmentGetters } =
  createNamespacedHelpers("APPOINTMENTS_COUNT");

const {
  mapActions: appointmentWeekCountActions,
  mapGetters: appointmentWeekCountGetters,
} = createNamespacedHelpers("APPOINTMENTS_LAST_WEEK_COUNT");

const { mapActions: clientActions, mapGetters: clientGetters } =
  createNamespacedHelpers("CLIENTS_COUNT");

const {
  mapActions: lastWeekClientCountActions,
  mapGetters: lastWeekClientCountGetters,
} = createNamespacedHelpers("CLIENTS_LAST_WEEK_COUNT");

const { mapActions: revenueActions, mapGetters: revenueGetters } =
  createNamespacedHelpers("REVENUE");

const {
  mapActions: previousRevenueActions,
  mapGetters: previousRevenueGetters,
} = createNamespacedHelpers("PREV_REVENUE");

const {
  mapActions: clientCurrentMonthCountActions,
  mapGetters: clientCurrentMonthCountGetters,
} = createNamespacedHelpers("CLIENTS_CURRENT_MONTH_COUNT");

const {
  mapActions: clientLastMonthCountActions,
  mapGetters: clientLastMonthCountGetters,
} = createNamespacedHelpers("CLIENTS_LAST_MONTH_COUNT");

export default Vue.extend<any, any, any, any>({
  name: "DashboardCards",
  data: () => ({
    currency: "KES",
  }),
  watch: {
    role: "fetchData",
  },
  created() {
    this.fetchData();
    // const setCurrency = (curr: string): void => {
    //   this.currency = curr;
    // };
  },
  computed: {
    ...appointmentGetters(["appointmentCount"]),
    ...appointmentWeekCountGetters({
      weekAppointmentCount: "appointmentCount",
    }),
    ...clientGetters(["clientCount"]),
    ...lastWeekClientCountGetters({
      lastWeekClientCount: "clientCount",
    }),
    ...revenueGetters(["revenueReport"]),
    ...previousRevenueGetters({ previousRevenueReport: "revenueReport" }),
    ...clientLastMonthCountGetters({ lastMonthClientCount: "clientCount" }),
    ...clientCurrentMonthCountGetters({
      currentMonthClientCount: "clientCount",
    }),
    role(): Role {
      return this.$store.getters.role;
    },
    totalRevenue(): number {
      let total = 0;
      this.revenueReport
        .filter((i) => i.report_type === "All Sales")
        .map((r: { _id: string; value: number }) => (total += r.value));
      return total;
    },
    previousTotalRevenue(): number {
      let total = 0;
      this.previousRevenueReport
        .filter((i) => i.report_type === "All Sales")
        .map((r: { _id: string; value: number }) => (total += r.value));
      return total;
    },
    appClientRate(): string {
      const growthRate =
        ((this.currentMonthClientCount - this.lastMonthClientCount) /
          this.lastMonthClientCount) *
        100;
      if (growthRate === Infinity) return `Not enough data`;
      return `${Math.trunc(growthRate) || 0}%`;
    },
  },
  methods: {
    ...appointmentActions(["fetchAppointmentCount"]),
    ...appointmentWeekCountActions({
      fetchWeekAppointmentCount: "fetchAppointmentCount",
    }),
    ...clientActions(["fetchClientCount"]),
    ...lastWeekClientCountActions({
      fetchLastWeekClientCount: "fetchClientCount",
    }),
    ...revenueActions(["fetchRevenueReport"]),
    ...previousRevenueActions({
      fetchPreviousRevenue: "fetchRevenueReport",
    }),
    ...clientCurrentMonthCountActions({
      fetchCurrentMonthClientCount: "fetchClientCount",
    }),
    ...clientLastMonthCountActions({
      fetchLastMonthClientCount: "fetchClientCount",
    }),
    fetchData() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;

        let startDate = moment().startOf("week").toISOString();
        let endDate = moment().toISOString();
        const params = `?businessId=${bid}&startDate=${startDate}&endDate=${endDate}`;
        this.fetchClientCount(params);
        this.fetchWeekAppointmentCount(params);
        startDate = moment(startDate).subtract(1, "week").toISOString();
        endDate = moment(endDate).subtract(1, "week").toISOString();
        this.fetchAppointmentCount(
          `?businessId=${bid}&startDate=${startDate}&endDate=${endDate}`
        );
        this.fetchLastWeekClientCount(
          `?businessId=${bid}&startDate=${startDate}`
        );

        endDate = moment().toISOString();
        startDate = moment(endDate).startOf("month").toISOString();
        this.fetchRevenueReport(
          `?startDate=${startDate}&endDate=${endDate}&businessId=${bid}`
        );

        this.fetchCurrentMonthClientCount(
          `?businessId=${bid}&endDate=${endDate}&startDate=${startDate}`
        );

        endDate = moment().subtract(1, "month").toISOString();
        startDate = moment(endDate).startOf("month").toISOString();
        this.fetchPreviousRevenue(
          `?businessId=${bid}&endDate=${endDate}&startDate=${startDate}`
        );

        this.fetchLastMonthClientCount(
          `?businessId=${bid}&endDate=${endDate}&startDate=${startDate}`
        );
      }
    },
    revenueStyle(num: number): any {
      return num.toString().length >= 6
        ? {
            "font-weight": "400",
            "font-size": "0.875rem",
          }
        : {
            "font-weight": "400",
            "font-size": "1.5rem",
          };
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("APPOINTMENTS_COUNT")) {
      this.$store.registerModule("APPOINTMENTS_COUNT", appointmentStoreModule);
    }
    if (!this.$store.hasModule("APPOINTMENTS_LAST_WEEK_COUNT")) {
      this.$store.registerModule(
        "APPOINTMENTS_LAST_WEEK_COUNT",
        appointmentStoreModule
      );
    }
    if (!this.$store.hasModule("CLIENTS_COUNT")) {
      this.$store.registerModule("CLIENTS_COUNT", clientStoreModule);
    }
    if (!this.$store.hasModule("CLIENTS_LAST_WEEK_COUNT")) {
      this.$store.registerModule("CLIENTS_LAST_WEEK_COUNT", clientStoreModule);
    }
    if (!this.$store.hasModule("REVENUE")) {
      this.$store.registerModule("REVENUE", revenueStoreModule);
    }
    if (!this.$store.hasModule("PREV_REVENUE")) {
      this.$store.registerModule("PREV_REVENUE", revenueStoreModule);
    }
    if (!this.$store.hasModule("CLIENTS_CURRENT_MONTH_COUNT")) {
      this.$store.registerModule(
        "CLIENTS_CURRENT_MONTH_COUNT",
        clientStoreModule
      );
    }
    if (!this.$store.hasModule("CLIENTS_LAST_MONTH_COUNT")) {
      this.$store.registerModule("CLIENTS_LAST_MONTH_COUNT", clientStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("APPOINTMENTS_LAST_WEEK_COUNT");
    this.$store.unregisterModule("APPOINTMENTS_COUNT");
    this.$store.unregisterModule("CLIENTS_LAST_WEEK_COUNT");
    this.$store.unregisterModule("CLIENTS_COUNT");
    this.$store.unregisterModule("REVENUE");
    this.$store.unregisterModule("PREV_REVENUE");
    this.$store.unregisterModule("CLIENTS_CURRENT_MONTH_COUNT");
    this.$store.unregisterModule("CLIENTS_LAST_MONTH_COUNT");
  },
});
