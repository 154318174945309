import { render, staticRenderFns } from "./DashboardCards.vue?vue&type=template&id=0df30c45&scoped=true&"
import script from "./DashboardCards.vue?vue&type=script&lang=ts&"
export * from "./DashboardCards.vue?vue&type=script&lang=ts&"
import style0 from "./DashboardCards.vue?vue&type=style&index=0&id=0df30c45&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0df30c45",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
installComponents(component, {VCard,VCardActions,VCardSubtitle,VCardTitle,VIcon,VItem,VItemGroup})
